import { createGlobalStyle } from 'styled-components';

const CriticalStyles = createGlobalStyle`
  body, .ant-layout, .ant-typography , .ant-col,  h1,h2,h3,h4,h5,h6,hr,p,pre,span,button,a {
    font-family: var(--font-noto)   !important;
    font-display:optional !important
  }

  .main {
      padding-top: 212px;
  }
  
  .solutions-angle {
    font-size: 0.75em;
  }

  .svg-inline--fa {
    display: inline-block;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }

  .fa-2x {
    font-size: 2em;
  }

  .ant-row {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
  }

  .ant-col-offset-2 {
    margin-inline-start: 8.333333333333332%;
  }

  @media (max-width: 1220px) {
      .main {
          padding-top: 0;
      }
  }

  @media (min-width: 768px) {

    .critical-pc-hidden {
      display: none
    }

    .critical-pc-show {
      display: none
    }

    .ant-layout-header {
      padding: 24px 0;
      line-height: 0;
      height: 100px;
      background-color: transparent;
    }

    .ant-col-md-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }

    .ant-col-md-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .tw-md-py-28 {
      padding-top: 7rem !important;
      padding-bottom: 7rem !important;
    }
  }

  @media (max-width: 768px) {


    .critical-mobile-hidden {
      display: none
    }

    .critical-mobile-show {
      display: block
    }

    .ant-col-xs-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .ant-col-xs-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .py-20 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }

    .ant-layout-header {
      height: 82px;
      padding: 24px 0px;
    }
  }


  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
  }

  body, .ant-layout, .ant-typography {
    margin: 0 0;
    padding: 0 0;
  }

  

  /*Menu override*/

  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: 0 solid rgba(5, 5, 5, 0.06);
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline * {
    background: #fff !important;
  }


  .ant-menu-submenu-title .ant-menu-title-content a svg  {
    transition: transform 500ms ease;
  }

  .ant-menu-submenu-title .ant-menu-title-content:hover a svg {
    transform: rotateX(180deg);
    transition: transform 500ms ease;
  }


  /* Filters selects*/

  .ant-select-dropdown {
    padding: 16px 0px 0px 0px;
    border: 1px solid rgba(39, 42, 61, 0.2);
    border-radius: 6px;
  }

  .ant-select-dropdown .ant-select-item {
    min-height: 14px !important;
  }

  .ant-select-focused .ant-select-selector, .ant-select:hover .ant-select-selector  {
    border-radius: 6px;
    box-shadow: none !important;
  }

  .ant-select-dropdown .ant-select-item.ant-select-item-group {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #272A3D;
    padding-left: 16px
  }

  .ant-select-dropdown .ant-select-item-option-active {
    background-color: rgba(45, 210, 200, 0.3) !important;
    border-radius: 0px;
  }

  .ant-select-item-option-content .option-type {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7A7C88;
  }

  .ant-select-item-option-content .option-value {
    font-weight: 400;
    font-size: 14px;
    color: #272A3D;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 44px;
  }


  /*Antd card override*/

  .ant-card:not(.ant-card-bordered) {
    box-shadow: none;
  }

  .ant-card .ant-card-cover img {
    border-radius: 0rem ;
  }

  /*Antd input override */

  .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none!important;
  }

  .chat_text_area {
    box-shadow: 0px 1px 12px 3px rgba(39, 42, 61, 0.05) !important;
    padding-bottom: 52px !important;

  }
  .chat_text_area:hover,
  .chat_text_area:focus,
  .chat_text_area:focus-within {
    border-color: #1a847c!important;
  }


  .chat_text_area .ant-input-data-count {
    left: 10px;
    bottom: 5px!important;
    font-size: 14px;
    color: rgba(39, 42, 61, 0.6);
  }


  /*Carousel dots*/

  .ant-carousel .slick-dots li button {
    height: 0px;
  }

  .ant-carousel .slick-dots li {
    width: 6px;
    height: 6px;
    border-radius:50%;
    background: #7D7F8B;
    margin-right:4px
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 6px;
    height: 6px;
    border-radius:50%;
    background: #2DD2C8;
  }

  /*Antd modal override*/

  .ant-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }


  /*Antd errors override*/

  .ant-form-item .ant-form-item-explain-error {
    color: #DC3545;
    font-size: 14px;
    line-height: 22px;
  }


  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 24px;
    display: none !important;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    display: none;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: rgba(39, 42, 61, 0.6);
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #ffffff;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding-top: 40px;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  /*Antd rate override*/

  .conversation-feedback .ant-rate-star .ant-rate-star-second path {
    fill: #7D7F8B !important;
  }

  .conversation-feedback .ant-rate-star:hover .ant-rate-star-second path {
    fill: #2DD2C8 !important;
  }

  .conversation-feedback .ant-rate-star  .ant-rate-star-second .currentRating path {
    fill: #2DD2C8 !important;
  }


  /*Antd tabs override*/

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2DD2C8 !important;
    font-weight: 500;
  }


  .ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #2DD2C8;
    pointer-events: none;
  }

  .ant-tabs-top >.ant-tabs-nav::before {
    border-bottom: 0px!important;
  }

  video {
    clip-path: inset(1px 1px);
  }

  /*Article content*/

  .article_content table {
    margin-top:16px;
    margin-bottom:16px;
  }
  .article_content td {
    border:1px solid #ddd;
    padding: 8px 16px;
  }

  .article_content ul li {
    list-style: square;
    margin-left: 32px;
  }

  .article_content ol li {
    list-style: decimal;
    margin-left: 32px;

  }

  .article_content p:not(:last-child) {
    margin-bottom: 14px;
  }

  /*Tailwind must be bottom*/


  *,:after,:before {
      box-sizing: border-box;
      border: 0 solid #e5e7eb
  }

  :after,:before {
      --tw-content: ""
  }

  html {
      line-height: 1.5;
      -webkit-text-size-adjust: 100%;
      -moz-tab-size: 4;
      -o-tab-size: 4;
      tab-size: 4;
      font-feature-settings: normal;
      font-variation-settings: normal
  }

  body {
      margin: 0;
      line-height: inherit
  }

  hr {
      height: 0;
      color: inherit;
      border-top-width: 1px
  }

  abbr:where([title]) {
      -webkit-text-decoration: underline dotted;
      text-decoration: underline dotted
  }

  h1,h2,h3,h4,h5,h6 {
      font-size: inherit;
      font-weight: inherit
  }

  a {
      color: inherit;
      text-decoration: inherit
  }

  b,strong {
      font-weight: bolder
  }

  code,kbd,pre,samp {
      font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
      font-size: 1em
  }

  small {
      font-size: 80%
  }

  sub,sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline
  }

  sub {
      bottom: -.25em
  }

  sup {
      top: -.5em
  }

  table {
      text-indent: 0;
      border-color: inherit;
      border-collapse: collapse
  }

  button,input,optgroup,select,textarea {
      font-family: inherit;
      font-size: 100%;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
      margin: 0;
      padding: 0
  }

  button,select {
      text-transform: none
  }

  [type=button],[type=reset],[type=submit],button {
      -webkit-appearance: button;
      background-color: transparent;
      background-image: none
  }

  :-moz-focusring {
      outline: auto
  }

  :-moz-ui-invalid {
      box-shadow: none
  }

  progress {
      vertical-align: baseline
  }

  ::-webkit-inner-spin-button,::-webkit-outer-spin-button {
      height: auto
  }

  [type=search] {
      -webkit-appearance: textfield;
      outline-offset: -2px
  }

  ::-webkit-search-decoration {
      -webkit-appearance: none
  }

  ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit
  }

  summary {
      display: list-item
  }

  blockquote,dd,dl,figure,h1,h2,h3,h4,h5,h6,hr,p,pre {
      margin: 0
  }

  fieldset {
      margin: 0
  }

  fieldset,legend {
      padding: 0
  }

  menu,ol,ul {
      list-style: none;
      margin: 0;
      padding: 0
  }

  textarea {
      resize: vertical
  }

  input::-moz-placeholder,textarea::-moz-placeholder {
      opacity: 1;
      color: #9ca3af
  }

  input::placeholder,textarea::placeholder {
      opacity: 1;
      color: #9ca3af
  }

  [role=button],button {
      cursor: pointer
  }

  :disabled {
      cursor: default
  }

  audio,canvas,embed,iframe,img,object,svg,video {
      display: block;
      vertical-align: middle
  }

  img,video {
      max-width: 100%;
      height: auto
  }

  [hidden] {
      display: none
  }


  .visible {
    visibility: visible !important
  }

  .fixed {
    position: fixed !important
  }

  .absolute {
    position: absolute !important
  }

  .relative {
    position: relative !important
  }

  .bottom-6 {
    bottom: 1.5rem !important
  }

  .left-6 {
    left: 1.5rem !important
  }

  .right-0 {
    right: 0 !important
  }

  .right-2 {
    right: .5rem !important
  }

  .right-6 {
    right: 1.5rem !important
  }

  .top-0 {
    top: 0 !important
  }

  .top-16 {
    top: 4rem !important
  }

  .isolate {
    isolation: isolate !important
  }

  .z-10 {
    z-index: 10 !important
  }

  .m-0 {
    margin: 0 !important
  }

  .m-auto {
    margin: auto !important
  }

  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important
  }

  .my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important
  }

  .my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .mb-0 {
    margin-bottom: 0 !important
  }

  .mb-1 {
    margin-bottom: .25rem !important
  }

  .mb-10 {
    margin-bottom: 2.5rem !important
  }

  .mb-12 {
    margin-bottom: 3rem !important
  }

  .mb-16 {
    margin-bottom: 4rem !important
  }

  .mb-2 {
    margin-bottom: .5rem !important
  }

  .mb-20 {
    margin-bottom: 5rem !important
  }

  .mb-24 {
    margin-bottom: 6rem !important
  }

  .mb-28 {
    margin-bottom: 7rem !important
  }

  .mb-3 {
    margin-bottom: .75rem !important
  }

  .mb-32 {
    margin-bottom: 8rem !important
  }

  .mb-4 {
    margin-bottom: 1rem !important
  }

  .mb-6 {
    margin-bottom: 1.5rem !important
  }

  .mb-7 {
    margin-bottom: 1.75rem !important
  }

  .mb-8 {
    margin-bottom: 2rem !important
  }

  .ml-1 {
    margin-left: .25rem !important
  }

  .ml-1\\.5 {
    margin-left: .375rem !important
  }

  .ml-2 {
    margin-left: .5rem !important
  }

  .ml-20 {
    margin-left: 5rem !important
  }

  .ml-3 {
    margin-left: .75rem !important
  }

  .ml-auto {
    margin-left: auto !important
  }

  .mr-1 {
    margin-right: .25rem !important
  }

  .mr-12 {
    margin-right: 3rem !important
  }

  .mr-2 {
    margin-right: .5rem !important
  }

  .mr-20 {
    margin-right: 5rem !important
  }

  .mr-3 {
    margin-right: .75rem !important
  }

  .mr-4 {
    margin-right: 1rem !important
  }

  .mr-6 {
    margin-right: 1.5rem !important
  }

  .mr-auto {
    margin-right: auto !important
  }

  .mt-0 {
    margin-top: 0 !important
  }

  .mt-10 {
    margin-top: 2.5rem !important
  }

  .mt-14 {
    margin-top: 3.5rem !important
  }

  .mt-2 {
    margin-top: .5rem !important
  }

  .mt-2\\.5 {
    margin-top: .625rem !important
  }

  .mt-20 {
    margin-top: 5rem !important
  }

  .mt-28 {
    margin-top: 7rem !important
  }

  .mt-3 {
    margin-top: .75rem !important
  }

  .mt-4 {
    margin-top: 1rem !important
  }

  .mt-48 {
    margin-top: 12rem !important
  }

  .mt-6 {
    margin-top: 1.5rem !important
  }

  .mt-8 {
    margin-top: 2rem !important
  }

  .mt-\\[120px\\] {
    margin-top: 120px !important
  }

  .block {
    display: block !important
  }

  .inline-block {
    display: inline-block !important
  }

  .inline {
    display: inline !important
  }

  .flex {
    display: flex !important
  }

  .contents {
    display: contents !important
  }

  .hidden {
    display: none !important
  }

  .h-24 {
    height: 6rem !important
  }

  .h-6 {
    height: 1.5rem !important
  }

  .h-\\[250px\\] {
    height: 250px !important
  }

  .h-fit {
    height: fit-content !important
  }

  .h-full {
    height: 100% !important
  }

  .max-h-72 {
    max-height: 18rem !important
  }

  .max-h-96 {
    max-height: 24rem !important
  }

  .min-h-full {
    min-height: 100% !important
  }

  .w-9 {
    width: 2.25rem !important
  }

  .w-\\[104px\\] {
    width: 104px !important
  }

  .w-\\[146px\\] {
    width: 146px !important
  }

  .w-full {
    width: 100% !important
  }

  .max-w-full {
    max-width: 100% !important
  }

  .flex-1 {
    flex: 1 1 0% !important
  }

  .flex-shrink {
    flex-shrink: 1 !important
  }

  .flex-grow {
    flex-grow: 1 !important
  }

  .transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
  }

  .flex-row {
    flex-direction: row !important
  }

  .flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-col {
    flex-direction: column !important
  }

  .flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .flex-wrap {
    flex-wrap: wrap !important
  }

  .items-start {
    align-items: flex-start !important
  }

  .items-end {
    align-items: flex-end !important
  }

  .items-center {
    align-items: center !important
  }

  .items-baseline {
    align-items: baseline !important
  }

  .justify-start {
    justify-content: flex-start !important
  }

  .justify-end {
    justify-content: flex-end !important
  }

  .justify-center {
    justify-content: center !important
  }

  .justify-between {
    justify-content: space-between !important
  }

  .justify-items-start {
    justify-items: start !important
  }

  .gap-4 {
    gap: 1rem !important
  }

  .gap-x-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important
  }

  .gap-x-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important
  }

  .gap-x-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important
  }

  .self-start {
    align-self: flex-start !important
  }

  .self-end {
    align-self: flex-end !important
  }

  .overflow-hidden {
    overflow: hidden !important
  }

  .whitespace-nowrap {
    white-space: nowrap !important
  }

  .whitespace-pre-line {
    white-space: pre-line !important
  }

  .whitespace-break-spaces {
    white-space: break-spaces !important
  }

  .rounded {
    border-radius: .25rem !important
  }

  .rounded-\\[20px\\] {
    border-radius: 20px !important
  }

  .rounded-full {
    border-radius: 9999px !important
  }

  .rounded-t-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important
  }

  .rounded-tl-none {
    border-top-left-radius: 0 !important
  }

  .rounded-tr-lg {
    border-top-right-radius: .5rem !important
  }

  .rounded-tr-md {
    border-top-right-radius: .375rem !important
  }

  .border {
    border-width: 1px !important
  }

  .border-0 {
    border-width: 0 !important
  }

  .border-l {
    border-left-width: 1px !important
  }

  .border-t {
    border-top-width: 1px !important
  }

  .border-solid {
    border-style: solid !important
  }

  .border-none {
    border-style: none !important
  }

  .border-\\[\\#D4D4D8\\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(212 212 216/var(--tw-border-opacity)) !important
  }

  .border-\\[\\#eee\\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(238 238 238/var(--tw-border-opacity)) !important
  }

  .border-gray-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(229 231 235/var(--tw-border-opacity)) !important
  }

  .bg-\\[\\#EAFBFA\\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(234 251 250/var(--tw-bg-opacity)) !important
  }

  .bg-\\[\\#F5F8F8\\] {
    background-color: rgb(245 248 248/var(--tw-bg-opacity)) !important
  }

  .bg-\\[\\#F5F8F8\\], .bg-white {
    --tw-bg-opacity: 1 !important
  }

  .bg-white {
    background-color: rgb(255 255 255/var(--tw-bg-opacity)) !important
  }

  .p-0 {
    padding: 0 !important
  }

  .p-2 {
    padding: .5rem !important
  }

  .p-3 {
    padding: .75rem !important
  }

  .p-4 {
    padding: 1rem !important
  }

  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
  }

  .px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
  }

  .px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }

  .px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }

  .py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important
  }

  .py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
  }

  .py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important
  }

  .py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .pb-0 {
    padding-bottom: 0 !important
  }

  .pb-10 {
    padding-bottom: 2.5rem !important
  }

  .pb-12 {
    padding-bottom: 3rem !important
  }

  .pb-16 {
    padding-bottom: 4rem !important
  }

  .pb-20 {
    padding-bottom: 5rem !important
  }

  .pb-4 {
    padding-bottom: 1rem !important
  }

  .pb-5 {
    padding-bottom: 1.25rem !important
  }

  .pb-6 {
    padding-bottom: 1.5rem !important
  }

  .pl-0 {
    padding-left: 0 !important
  }

  .pl-4 {
    padding-left: 1rem !important
  }

  .pl-8 {
    padding-left: 2rem !important
  }

  .pr-0 {
    padding-right: 0 !important
  }

  .pr-0\\.5 {
    padding-right: .125rem !important
  }

  .pr-10 {
    padding-right: 2.5rem !important
  }

  .pr-2 {
    padding-right: .5rem !important
  }

  .pr-4 {
    padding-right: 1rem !important
  }

  .pt-0 {
    padding-top: 0 !important
  }

  .pt-1 {
    padding-top: .25rem !important
  }

  .pt-10 {
    padding-top: 2.5rem !important
  }

  .pt-12 {
    padding-top: 3rem !important
  }

  .pt-2 {
    padding-top: .5rem !important
  }

  .pt-20 {
    padding-top: 5rem !important
  }

  .pt-4 {
    padding-top: 1rem !important
  }

  .pt-40 {
    padding-top: 10rem !important
  }

  .pt-6 {
    padding-top: 1.5rem !important
  }

  .pt-7 {
    padding-top: 1.75rem !important
  }

  .pt-96 {
    padding-top: 24rem !important
  }

  .text-left {
    text-align: left !important
  }

  .text-center {
    text-align: center !important
  }

  .text-right {
    text-align: right !important
  }

  .align-middle {
    vertical-align: middle !important
  }

  .text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important
  }

  .text-\\[18px\\] {
    font-size: 18px !important
  }

  .text-\\[40px\\] {
    font-size: 40px !important
  }

  .text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important
  }

  .text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important
  }

  .text-sm {
    font-size: .875rem !important;
    line-height: 1.25rem !important
  }

  .font-bold {
    font-weight: 700 !important
  }

  .font-medium {
    font-weight: 500 !important
  }

  .font-semibold {
    font-weight: 600 !important
  }

  .leading-\\[26px\\] {
    line-height: 26px !important
  }

  .text-\\[\\#2DD2C8\\] {
    --tw-text-opacity: 1 !important;
    color: rgb(45 210 200/var(--tw-text-opacity)) !important
  }

  .underline {
    text-decoration-line: underline !important
  }

  .no-underline {
    text-decoration-line: none !important
  }

  .blur {
    --tw-blur: blur(8px) !important
  }

  .blur, .filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
  }

  .transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
    transition-duration: .15s !important
  }

  @media (min-width: 768px) {
      .tw-md-mb-28 {
          margin-bottom: 7rem !important
      }
      .tw-md-bottom-8 {
          bottom: 2rem !important
      }

      .tw-md-right-2 {
          right: .5rem !important
      }

      .tw-md-right-4 {
          right: 1rem !important
      }

      .tw-md-my-28 {
          margin-top: 7rem !important;
          margin-bottom: 7rem !important
      }

      .tw-md-mb-0 {
          margin-bottom: 0 !important
      }

      .tw-md-mb-10 {
          margin-bottom: 2.5rem !important
      }

      .tw-md-mb-20 {
          margin-bottom: 5rem !important
      }

      .tw-md-mb-28 {
          margin-bottom: 7rem !important
      }

      .tw-md-mb-4 {
          margin-bottom: 1rem !important
      }

      .tw-md-mb-6 {
          margin-bottom: 1.5rem !important
      }

      .tw-md-mr-4 {
          margin-right: 1rem !important
      }

      .tw-md-mt-10 {
          margin-top: 2.5rem !important
      }

      .tw-md-mt-14 {
          margin-top: 3.5rem !important
      }

      .tw-md-mt-20 {
          margin-top: 5rem !important
      }

      .tw-md-mt-28 {
          margin-top: 7rem !important
      }

      .tw-md-mt-40 {
          margin-top: 10rem !important
      }

      .tw-md-mt-7 {
          margin-top: 1.75rem !important
      }

      .tw-md-mt-9 {
          margin-top: 2.25rem !important
      }

      .tw-md-inline-block {
          display: inline-block !important
      }

      .tw-md-inline {
          display: inline !important
      }

      .tw-md-flex {
          display: flex !important
      }

      .tw-md-h-96 {
          height: 24rem !important
      }

      .tw-md-max-h-max {
          max-height: -moz-max-content !important;
          max-height: max-content !important
      }

      .tw-md-w-\\[600px\\] {
          width: 600px !important
      }

      .tw-md-w-auto {
          width: auto !important
      }

      .tw-md-flex-row {
          flex-direction: row !important
      }

      .tw-md-flex-row-reverse {
          flex-direction: row-reverse !important
      }

      .tw-md-items-start {
          align-items: flex-start !important
      }

      .tw-md-items-center {
          align-items: center !important
      }

      .tw-md-justify-start {
          justify-content: flex-start !important
      }

      .tw-md-justify-end {
          justify-content: flex-end !important
      }

      .tw-md-justify-center {
          justify-content: center !important
      }

      .tw-md-justify-around {
          justify-content: space-around !important
      }

      .tw-md-border-solid {
          border-style: solid !important
      }

      .tw-md-p-20 {
          padding: 5rem !important
      }

      .tw-md-p-6 {
          padding: 1.5rem !important
      }

      .tw-md-px-2 {
          padding-left: .5rem !important;
          padding-right: .5rem !important
      }

      .tw-md-px-20 {
          padding-left: 5rem !important;
          padding-right: 5rem !important
      }

      .tw-md-px-28 {
          padding-left: 7rem !important;
          padding-right: 7rem !important
      }

      .tw-md-px-48 {
          padding-left: 12rem !important;
          padding-right: 12rem !important
      }

      .tw-md-px-6 {
          padding-left: 1.5rem !important;
          padding-right: 1.5rem !important
      }

      .tw-md-py-0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important
      }

      .tw-md-py-20 {
          padding-top: 5rem !important;
          padding-bottom: 5rem !important
      }

      .tw-md-py-28 {
          padding-top: 7rem !important;
          padding-bottom: 7rem !important
      }

      .tw-md-pb-11 {
          padding-bottom: 2.75rem !important
      }

      .tw-md-pb-20 {
          padding-bottom: 5rem !important
      }

      .tw-md-pb-28 {
          padding-bottom: 7rem !important
      }

      .tw-md-pl-10 {
          padding-left: 2.5rem !important
      }

      .tw-md-pl-3 {
          padding-left: .75rem !important
      }

      .tw-md-pl-40 {
          padding-left: 10rem !important
      }

      .tw-md-pr-28 {
          padding-right: 7rem !important
      }

      .tw-md-pr-40 {
          padding-right: 10rem !important
      }

      .tw-md-pt-0 {
          padding-top: 0 !important
      }

      .tw-md-pt-10 {
          padding-top: 2.5rem !important
      }

      .tw-md-pt-14 {
          padding-top: 3.5rem !important
      }

      .tw-md-pt-20 {
          padding-top: 5rem !important
      }

      .tw-md-pt-28 {
          padding-top: 7rem !important
      }

      .tw-md-pt-9 {
          padding-top: 2.25rem !important
      }

      .tw-md-text-left {
          text-align: left !important
      }

      .tw-md-text-center {
          text-align: center !important
      }

      .tw-md-text-right {
          text-align: right !important
      }

      .tw-md-text-start {
          text-align: start !important
      }

      .tw-md-text-end {
          text-align: end !important
      }

      .tw-md-text-\\[22px\\] {
          font-size: 22px !important
      }
  }



`;

export default CriticalStyles;
