'use client';

import React, { useEffect } from 'react';
import Script from 'next/script';
import { getGeneratedPublicUserId } from '@/utils/helper';
import { Analytics, GA_MEASUREMENT_ID } from '@/libs/GoogleAnalytics';
import { usePathname, useSearchParams } from 'next/navigation';

const GoogleAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    Analytics.init();
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const url = pathname + searchParams.toString();

    Analytics.sendPageViewEventToGA(url);

    // Generating userId for public users by Fingerprint
    // and passing generated userId to GA
    setTimeout(() => {
      const publicUserId = getGeneratedPublicUserId();
      publicUserId.then((id) => {
        Analytics.addUserIdToGADataLayer(id);
      });
    }, 2500);

  }, [pathname, searchParams, Analytics]);

  if (typeof window === 'undefined' || !GA_MEASUREMENT_ID) {
    return null;
  }

  return (
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
    />
  );
};

export default GoogleAnalytics;
