'use client';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import CriticalStyles from "@/assets/styles/critical";
// import {ConfigProvider, theme} from "antd";

type UIContextType = {
  isMobileView: boolean;
  isTabletView: boolean;
  isDesktopView: boolean;
};

export const UIContext = createContext<UIContextType>({
  isMobileView: false,
  isTabletView: false,
  isDesktopView: true,
});

export const UIProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [screenSizes, setScreenSizes] = useState<any>({
    winWidth: '',
    winHeight: '',
  });
  //! Screen Resize
  let resizeTimeout: string | number | NodeJS.Timeout | undefined;
  const screenResize = () => {
    resizeTimeout && clearTimeout(resizeTimeout);

    resizeTimeout = setTimeout(() => {
      setScreenSizes({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
    }, 500);
  };

  useEffect(() => {
    window.addEventListener('resize', screenResize);
    screenResize();
    return () => {
      window.removeEventListener('resize', screenResize);
    };
  }, []);

  const isMobileView = useMemo(() => {
    return screenSizes.winWidth >= 320 && screenSizes.winWidth < 768;
  }, [screenSizes.winWidth]);

  const isTabletView = useMemo(() => {
    return screenSizes.winWidth >= 768 && screenSizes.winWidth < 1024;
  }, [screenSizes.winWidth]);

  const isDesktopView = useMemo(() => {
    return screenSizes.winWidth >= 1024;
  }, [screenSizes.winWidth]);

  return (
    <UIContext.Provider
      value={{
        isMobileView,
        isTabletView,
        isDesktopView,
      }}
    >
      <CriticalStyles />
      {children}
    </UIContext.Provider>

  );
};
